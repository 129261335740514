<template>
  <section>
    <div class="content-header">
      <h2>Universal Design for Learning: Theory and Practice</h2>
    </div>
    <div class="content-wrapper">
      <p>Learn about the research, theory and practice of Universal Design for Learning (UDL) from its original developers, Dr. Anne Meyer and Dr. David Rose, <a href="https://www.cast.org/products-services/resources/2014/universal-design-learning-theory-practice-udl-meyer" target="_blank">in a book</a> published by the Centre for Applied Special Technology (CAST).</p>
      <ul>
        <li class="pb-2">Dr. Anne Meyer is an educator and clinical psychologist focused on the psychology of learning and learning disabilities. She has been a leading author in the development of UDL approaches including focus on accessible curriculum and accessible digital teaching and learning environments.</li>
        <li>Dr. David Rose is a developmental neuropsychologist and education expert who has focused his research and career on the science of learning, and development of new technologist to support learning.</li>
      </ul>
      <p>The book outlines the UDL Framework and Guidelines developed by CAST, cutting edge evidence on the science of learning that underpins the importance of UDL, as well as tangible strategies to integrate UDL approaches into your teaching.</p>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
